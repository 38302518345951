<template>
  <span>{{ member.userName }}({{ member.userCode }})</span>
</template>

<script>
import memberApi from '@/services/api/member'
export default {
  props: {
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      member: {}
    }
  },
  watch: {
    id: function(id) {
      this.fetchCached(id)
    }
  },
  mounted() {
    this.fetchCached(this.id)
  },
  methods: {
    async fetchCached(id) {
      if (id && (!this.member || this.member.id != id)) {
        this.member = await memberApi.fetchCached(id)
      }
      this.member = await memberApi.fetchCached(id)
    }
  }
}
</script>
